
import Link from 'gatsby-link';
import styled from 'styled-components';
import { Link as LinkScroll } from 'react-scroll';

export const InternalButtonLink = styled(Link)`
  display: inline-block;
  background-color: ${props => (props.btncolor ? props.btncolor : props.theme.colorButtonPrimaryBG)};
  min-width: 206px;
  color: ${props => (props.btntextcolor ? props.btntextColor : '#fff')};
  font-size: 18px;
  text-align: center;  
  line-height: 1.5;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;    
  padding-top: 12px;
  padding-bottom: 12px;    
  border: 2px solid ${props => (props.btnbordercolor ? props.btnbordercolor : props.theme.colorButtonPrimaryBorder)};
  border-radius: 7px;
  transition: ease .2s all;
  &:hover,
  &:focus {
    background-color: ${props => (props.btncolorhover ? props.btncolorhover : props.theme.colorButtonPrimaryBGHover)};
    color: ${props => (props.btntexthovercolor ? props.btntexthovercolor : '#fff')};
    &:before {
      content: none;
    }
  }  
`

export const ExternalButtonLink = styled.a`
  display: inline-block;
  background-color: ${props => (props.btncolor ? props.btncolor : props.theme.colorButtonPrimaryBG)};
  min-width: 206px;
  color: ${props => (props.btntextcolor ? props.btntextColor : '#fff')};
  font-size: 18px;
  text-align: center;  
  line-height: 1.5;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;    
  padding-top: 12px;
  padding-bottom: 12px;    
  border: 2px solid ${props => (props.btnbordercolor ? props.btnbordercolor : props.theme.colorButtonPrimaryBorder)};
  border-radius: 7px;
  transition: ease .2s all;
  &:hover,
  &:focus {
    background-color: ${props => (props.btncolorhover ? props.btncolorhover : props.theme.colorButtonPrimaryBGHover)};
    color: ${props => (props.btntexthovercolor ? props.btntexthovercolor : '#fff')};
    &:before {
      content: none;
    }
  }  
`

export const ScrollButtonLink = styled(LinkScroll)`
  display: inline-block;
  background-color: ${props => (props.btncolor ? props.btncolor : props.theme.colorButtonPrimaryBG)};
  min-width: 206px;
  color: ${props => (props.btntextcolor ? props.btntextColor : '#fff')};
  font-size: 18px;
  text-align: center;  
  line-height: 1.5;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;    
  padding-top: 12px;
  padding-bottom: 12px;    
  border: 2px solid ${props => (props.btnbordercolor ? props.btnbordercolor : props.theme.colorButtonPrimaryBorder)};
  border-radius: 7px;
  cursor: pointer;
  transition: ease .2s all;
  &:hover,
  &:focus {
    background-color: ${props => (props.btncolorhover ? props.btncolorhover : props.theme.colorButtonPrimaryBGHover)};
    color: ${props => (props.btntexthovercolor ? props.btntexthovercolor : '#fff')};
    &:before {
      content: none;
    }
  }  
`

export const MainButton = styled.button`
  display: inline-block;
  background-color: ${props => (props.btncolor ? props.btncolor : props.theme.colorButtonPrimaryBG)};
  min-width: 206px;
  color: ${props => (props.btntextcolor ? props.btntextColor : '#fff')};
  font-size: 18px;
  text-align: center;  
  line-height: 1.5;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;    
  padding-top: 12px;
  padding-bottom: 12px;    
  border: 2px solid ${props => (props.btnbordercolor ? props.btnbordercolor : props.theme.colorButtonPrimaryBorder)};
  border-radius: 3px;
  cursor: pointer;
  transition: ease .2s all;
  &:hover,
  &:focus {
    background-color: ${props => (props.btncolorhover ? props.btncolorhover : props.theme.colorButtonPrimaryBGHover)};
    color: ${props => (props.btntexthovercolor ? props.btntexthovercolor : '#fff')};
  }  
`


export const InternalButtonLinkArrow = styled(Link)`
  position: relative;
  font-size: 14px;
  margin-right: 20px;
  &:after {
    content: "→";
    display: block;
    position: absolute;
    top: 0;
    right: -25px;
    bottom: 0;
    line-height: 1;
  }
`