import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import 'intl';
import 'bootstrap/dist/css/bootstrap.min.css';

import Header from '../header';
import Footer from '../footer';
import CookieInfo from '../cookie-info';

const theme = {
  colorPrimary: '#e9280c',
  colorSecondary: '#3e434a',
  colorThird: '#8c989f',
  colorPrimaryBG: '#2993fb',
  colorSecondaryBG: '#f7f8fa',
  colorLinkHoverLine: '#dce0e2',
  colorButtonPrimaryBG: '#2381dd',
  colorButtonPrimaryBGHover: '#2993fb',
  colorButtonPrimaryBorder: '#4aa6fb',
  colorButtonSecondaryBG: '#e9280c',
  colorButtonSecondaryBorder: '#e9280c',
  colorButtonThirdBG: '#8c989f',
  colorButtonThirdBorder: '#fff',
}

const GlobalStyles = createGlobalStyle`
  body {
    /* background-color: ${theme.colorPrimaryBG}; */
    color: ${theme.colorThird};
    font-size: 16px;
    font-family: "Arial", sans-serif;
    font-weight: 400;
  }
  p {
    margin-bottom: 15px;
  }
  a {
    position:relative;
    color: ${theme.colorPrimary};
    transition: ease .2s all;
    &:hover {
      color: ${theme.colorPrimary};
      text-decoration: none;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -2px;
        display: block;
        border-bottom: 1px solid ${theme.colorLinkHoverLine};
      }
    }
  }
`

const Layout = ({ baseUrl="/", children }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              metaDescription
              metaKeywords
            }
          }
        }
      `}
      render={data => (
        <React.Fragment>
          <GlobalStyles />
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: 'description', content: data.site.siteMetadata.metaDescription },
              { name: 'keywords', content: data.site.siteMetadata.metaKeywords },
            ]}
          >
          </Helmet>
          <ThemeProvider theme={theme}>
            <>
              <Header baseUrl={baseUrl} />

              {children}

              <Footer />
              <CookieInfo />
            </>
          </ThemeProvider>
        </React.Fragment>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
