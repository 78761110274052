import styled from 'styled-components';

export const TitleLargeH2 = styled.h2`
  color: ${props => (props.titleColor ? props.titleColor : props.theme.colorSecondary)};
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 25px;
`

export const TitleMiddleH2 = styled.h2`
  color: ${props => (props.titleColor ? props.titleColor : props.theme.colorSecondary)};
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 25px;
`

export const TitleLittleH2 = styled.h2`
  color: ${props => (props.titleColor ? props.titleColor : props.theme.colorSecondary)};
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 25px;
`

export const TitleMiddleH3 = styled.h3`
  color: ${props => (props.titleColor ? props.titleColor : props.theme.colorSecondary)};
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 25px;
`

export const TitleLittleH3 = styled.h3`
  color: ${props => (props.titleColor ? props.titleColor : props.theme.colorSecondary)};
  font-size: 18px;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 10px;
`