import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';
import { Container, Row, Col } from 'reactstrap';
import Cookies from 'js-cookie';

import orphanRemove from './orphan-remove-text';
import { MainButton } from './buttons';


const CookieInfoSection = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  color: #3e434a;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid #f3f3f3;
  z-index: 9999;
`

const CookieInfoColDesc = styled(Col)`
  font-size: 14px;
  @media (max-width: 575px) {
    font-size: 12px;
  }
  p {
    margin-bottom: 3px;
    @media (max-width: 575px) {
      margin-bottom: 2px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    color: #2c72b5;
    text-decoration: underline;
    &:hover,
    &:focus {
      color: #fff;
      &:before {
        display: none;
      }
    }
  }
`

const CookieInfoColButton = styled(Col)`
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  @media (max-width: 767px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
`

const CookieInfoButton = styled(MainButton)`
  font-size: 16px;
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: 767px) {
    width: 100%;
  }
  @media (max-width: 575px) {
    font-size: 14px;
  }
  @media (max-width: 340px) {
    font-size: 12px;
  }
`

const CookieInfoComponent = (props) => {
  return (<StaticQuery
    query={graphql`
      query CookieInfoQuery {
        markdownRemark(frontmatter: { section: { eq: "cookie-info" } }) {
          html
          frontmatter {
            locale
            button
          }
        }
      }
    `}
    render={data => {
      const { html, frontmatter } = data.markdownRemark;

      return <CookieInfoSection>
        <Container>
          <Row>
            <CookieInfoColDesc md={6} lg={7} xl={8} dangerouslySetInnerHTML={{ __html: orphanRemove(html) }} />
            <CookieInfoColButton md={6} lg={5} xl={4}>
              <CookieInfoButton
                btncolor={'#2c72b5'}
                btnbordercolor={'#4aa6fb'}
                btncolorhover={'#4aa6fb'}
                onClick={props.btnClick()}
              >{frontmatter.button}</CookieInfoButton>
            </CookieInfoColButton>
          </Row>
        </Container>
      </CookieInfoSection>
    }}
  />)
}

class CookieInfo extends React.Component {

  constructor (props) {
    super(props)

    this.state = {
      isCookie: false
    }

    this.cookieAgreement = this.cookieAgreement.bind(this);
  }

  componentDidMount() {
    if (Cookies.get('policyCookies') !== 'true') {
      this.setState({ isCookie: !this.state.isCookie });
    }
  }

  cookieAgreement (e) {
    Cookies.set('policyCookies', 'true', { expires: 7 });
    this.setState({ isCookie: !this.state.isCookie });
  }

  render () {
    return (<>
      {this.state.isCookie ? <CookieInfoComponent btnClick={() => this.cookieAgreement} /> : null}
    </>)
  }
}

export default CookieInfo
