import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import Link, { withPrefix } from 'gatsby-link';
import { injectIntl, FormattedMessage } from 'react-intl';
import styled, { withTheme } from 'styled-components';
import Sticky from 'react-stickynode';
import Scrollspy from 'react-scrollspy';
import { Link as LinkScroll } from 'react-scroll';
import {
  Container,
  Row,
  Col,
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem } from 'reactstrap';


import HamburgerIcon from '../images/icons/hamburger.svg';


const HeaderSection = styled.header`
  background-color: #fff;
  border-bottom: 1px solid #fff;
`

const HeaderContainer = styled(Container)``

const HeaderContact = styled.div`
  background-color: #f3f3f3;
  padding-top: 12px;
  padding-bottom: 12px;
  @media (max-width: 575px) {
    padding-top: 5px;
    padding-bottom: 5px;
  }
`


const HeaderContactCallItem = styled.span`
  display: inline-block;
  font-size: 12px;
  transition: ease .2s all;
  @media (min-width: 768px) {
    margin-right: 15px;
  }
  @media (max-width: 767px) {
    padding-left: 7px;
    padding-right: 7px;
  }
  @media (max-width: 575px) {
    font-size: 11px;
  }
`

const HeaderNavbarOuterWrapper = styled(Row)``

const HeaderNavbarInnerWrapper = styled(Col)`
  padding-left: 0;
  padding-right: 0;
`

const HeaderScrollspy = styled(Scrollspy)`
  display: inherit;
  li {
    &.active {
      a {
        color: #2381dd;
      }
    }
  }
`

const HeaderNavbar = styled(Navbar)`
  min-height: 90px;
  @media (max-width: 575px) {
    min-height: 50px;
  }
`

const HeaderNavbarNav = styled(Nav)`
  margin-left: auto;
  @media (max-width: 991px) {
    display: block;
  }
`

const HeaderNavbarToggler = styled(NavbarToggler)`
  span {
    background-image: url(${HamburgerIcon});
  }
`

const HeaderNavbarNavItem = styled(NavItem)`
  @media (max-width: 991px) {
    display: block;
  }
  &:last-child {
    a {
      @media (min-width: 992px) {
        padding-right: 0;
      }
    }
  }
`

const HeaderNavbarLink = styled(Link)`
  display: block;
  padding: .5rem 1rem;
  color: #3e434b;
  padding-right: .5rem;
  padding-left: .5rem;
  transition: ease .2s all;
  &:hover {
    color: #2381dd;
    &:before {
      content: none;
    }
  }
  &.active {
    font-weight: 700;
  }
`

const HeaderNavbarLinkScroll = styled(LinkScroll)`
  display: block;
  padding: .5rem 1rem;
  color: #3e434b;
  padding-right: .5rem;
  padding-left: .5rem;
  transition: ease .2s all;
  &:hover
   {
    color: #2381dd;
    &:before {
      content: none;
    }
  }
  &.active {
    font-weight: 700;
  }
`

const HeaderLogoLink = styled(Link)`
  display: inline-block;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  @media (max-width: 575px) {
    height: 40px;
    padding-top: 0;
    padding-bottom: 0;
  }
  &:hover,
  &:focus {
    &:before {
      content: none;
    }
  }
`

const HeaderLogoImgWrapper = styled.span`
  display: inline-block;
  width: 170px;
  height: 55px;
  float: left;
  transition: ease .2s all;
  @media (max-width: 575px) {
    width: 120px;
    height: 40px;
  }
`


const HeaderSticky = styled(Sticky)`
  ${HeaderContact} {
    transition: ease .2s all;
  }
  &.active {
    ${HeaderSection} {
      border-bottom: 1px solid #f3f3f3;
    }
    ${HeaderContact} {
      display: none;
      height: 0;
      padding-top: 10px;
      padding-bottom: 10px;
      transition: ease .2s all;
    }
    ${HeaderContactCallItem} {
      font-size: 11px;
    }
    ${HeaderLogoImgWrapper} {
      width: 100px;
      height: 35px;
    }
    ${HeaderNavbar} {
      min-height: 60px;
      @media (max-width: 575px) {
        min-height: 50px;
      }
    }
    ${HeaderNavbarLink} {
      font-size: 15px;
    }
  }
`


class Header extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.handleStickyStateChange = this.handleStickyStateChange.bind(this);

    this.state = {
      isOpen: false,
      isHeaderSticky: false
    };
  }

  toggle() {
    if ( typeof document !== 'undefined' || typeof window !== 'undefined' ) {
      const widthWindow = document.body.clientWidth || window.innerWidth;

      if (widthWindow <= 991) {
        this.setState({
          isOpen: !this.state.isOpen
        });
      }
    }
  }

  handleStickyStateChange(status) {
    if (status.status === HeaderSticky.STATUS_FIXED) {
      this.setState({
        isHeaderSticky: true
      });
    }
    if (status.status === HeaderSticky.STATUS_ORIGINAL) {
      this.setState({
        isHeaderSticky: false
      });
    }
  }

  render() {
    const baseUrl = this.props.baseUrl
    const linkProps = baseUrl === "" ? {
      onClick: this.toggle,
      smooth: true,
    } : {
      as: 'a',
      to: null,
      offset: null,
    }

    return (
      <HeaderSticky enabled={true} onStateChange={this.handleStickyStateChange} innerZ={'9999'}>
        <HeaderSection>
          <HeaderContainer>
            <HeaderNavbarOuterWrapper>
              <HeaderNavbarInnerWrapper>
                <HeaderNavbar expand="lg">
                  <HeaderLogoLink to={withPrefix('/')}>
                    <HeaderLogoImgWrapper>
                      <StaticImage
                        src="../images/logo.jpg"
                        width={220}
                        alt="Brookfield Partners" />
                    </HeaderLogoImgWrapper>
                  </HeaderLogoLink>
                  <HeaderNavbarToggler onClick={this.toggle} aria-label="Pokaż lub schowaj menu" />
                  <Collapse isOpen={this.state.isOpen} navbar>
                    <HeaderNavbarNav navbar>
                      <HeaderScrollspy items={ ['slider', 'about', 'services', 'team', 'offices', 'blog', 'contactForm'] } currentClassName="active" componentTag={'div'} offset={-100}>
                        <HeaderNavbarNavItem>
                          <HeaderNavbarLinkScroll
                            href={`${baseUrl}#slider`}
                            to={'slider'}
                            offset={-60}
                            {...linkProps}
                          />
                        </HeaderNavbarNavItem>
                        <HeaderNavbarNavItem>
                          <HeaderNavbarLinkScroll
                            href={`${baseUrl}#about`}
                            to={'about'}
                            offset={-60}
                            {...linkProps}
                          >
                            <FormattedMessage id="HEADER_MENU_ABOUT" />
                          </HeaderNavbarLinkScroll>
                        </HeaderNavbarNavItem>
                        <HeaderNavbarNavItem>
                          <HeaderNavbarLinkScroll
                            href={`${baseUrl}#services`}
                            to={'services'}
                            offset={-95}
                            {...linkProps}
                          >
                            <FormattedMessage id="HEADER_MENU_SERVICES" />
                          </HeaderNavbarLinkScroll>
                        </HeaderNavbarNavItem>
                        <HeaderNavbarNavItem>
                          <HeaderNavbarLinkScroll
                            href={`${baseUrl}#team`}
                            to={'team'}
                            offset={-70}
                            {...linkProps}
                          >
                            <FormattedMessage id="HEADER_MENU_TEAM" />
                          </HeaderNavbarLinkScroll>
                        </HeaderNavbarNavItem>
                        <HeaderNavbarNavItem>
                          <HeaderNavbarLinkScroll
                            href={`${baseUrl}#offices`}
                            to={'offices'}
                            offset={-70}
                            {...linkProps}
                          >
                            <FormattedMessage id="HEADER_MENU_OFFICES" />
                          </HeaderNavbarLinkScroll>
                        </HeaderNavbarNavItem>
                        <HeaderNavbarNavItem>
                          <HeaderNavbarLinkScroll
                            href={`${baseUrl}#opinions`}
                            to={"opinions"}
                            {...linkProps}
                          >
                            <FormattedMessage id="HEADER_MENU_OPINIONS" />
                          </HeaderNavbarLinkScroll>
                        </HeaderNavbarNavItem>
                        <HeaderNavbarNavItem>
                          <HeaderNavbarLink href='/media'>
                            <FormattedMessage id="HEADER_MENU_MEDIA" />
                          </HeaderNavbarLink>
                        </HeaderNavbarNavItem>
                        <HeaderNavbarNavItem>
                          <HeaderNavbarLinkScroll
                            href={`${baseUrl}#contactForm`}
                            to={'contactForm'}
                            offset={-100}
                            {...linkProps}
                          >
                            <FormattedMessage id="HEADER_MENU_CONTACT" />
                          </HeaderNavbarLinkScroll>
                        </HeaderNavbarNavItem>
                      </HeaderScrollspy>
                    </HeaderNavbarNav>
                  </Collapse>
                </HeaderNavbar>
              </HeaderNavbarInnerWrapper>
            </HeaderNavbarOuterWrapper>
          </HeaderContainer>
        </HeaderSection>
      </HeaderSticky>
    );
  }
}

export default injectIntl(withTheme(Header))
