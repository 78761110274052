import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import styled, { withTheme } from 'styled-components';
// import { Container, Row, Col, Button, Form, Input } from 'reactstrap';
import { Container, Row, Col } from 'reactstrap';

import { TitleLittleH2 } from './titles';
import TwitterIcon from '../images/icons/twitter-footer.svg';
import FacebookIcon from '../images/icons/facebook-footer.svg';
import PinterestIcon from '../images/icons/pinterest-footer.svg';

const FooterSection = styled.footer`
  background-color: #2c3339;
  color: #8c989e;
  font-size: 14px;
  padding-top: 65px;
  padding-bottom: 75px;
  @media (max-width: 575px) {
    padding-top: 50px;
    padding-bottom: 25px;
  }
  strong {
    color: #656b74;
    font-weight: 400;
  }
  a {
    color: #8c989e;
    text-decoration: underline;
    &:hover,
    &:focus {
      &:before {
        content: none;
      }
    }
  }
`

const FooterColumn = styled(Col)`
  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
`

const FooterTitle = styled(TitleLittleH2)`
  color: #fff;
`

const FooterSocialIcon = styled.a`
  display: none;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  &:hover,
  &:focus {
    &:before {
      content: none;
    }
  }
  @media (min-width: 768px) {
    margin-right: 15px;
  }
`

const FooterSocialIconTwitter = styled(FooterSocialIcon)`
  background-image: url(${TwitterIcon});
`

const FooterSocialIconFacebook = styled(FooterSocialIcon)`
  background-image: url(${FacebookIcon});
`

const FooterSocialIconPinterest = styled(FooterSocialIcon)`
  background-image: url(${PinterestIcon});
`

// const FooterForm = styled(Form)`
//   margin-top: 20px;
// `

// const FooterFormGroup = styled.div`
//   position: relative;
//   padding-right: 58px;
// `

// const FooterFormInput = styled(Input)`
//   background-color: #2c3339;
//   width: 100%!important;
//   height: 40px;
//   border: 1px solid rgb(140, 152, 159);
//   border-radius: 3px;
//   &:hover,
//   &:focus {
//     background-color: #2c3339!important;
//     outline: none!important;
//   }
//   &:active {
//     background-color: #2c3339!important;
//     outline: none!important;
//   }
// `

// const FooterFormButtonSubmit = styled(Button)`
//   position: absolute;
//   top: 0;
//   right: 0;
//   background-color: ${props => props.theme.colorPrimary};
//   width: 55px;
//   height: 40px;
//   margin-left: 3px;
//   border: 1px solid ${props => props.theme.colorPrimary};
//   border-radius: 3px;
//   &:hover,
//   &:focus,
//   &:active {
//     background-color: ${props => props.theme.colorPrimary};
//     border: 1px solid ${props => props.theme.colorPrimary};
//   }
// `


const Footer = () => <FooterSection>
    <StaticQuery
    query={graphql`
      query FooterQuery {
        markdownRemark(frontmatter: { section: { eq: "footer" } }) {
          frontmatter {
            locale
            contact_title
            contact_address
            contact_phone
            contact_email
          }
        }
      }
    `}
    render={data => {
      const { frontmatter } = data.markdownRemark;

      return (
        <Container>
          <Row>
            {/* <FooterColumn md={3}>
              <FooterTitle>Paramount</FooterTitle>
              <p>The alarming thing is, the mistakes that produce these regrets are all errors of omission. You forget your dreams, ignore your family, suppress your feelings, neglect your friends, and forget to be happy. </p>
            </FooterColumn> */}
            {/* <FooterColumn md={3}>
              <FooterTitle>Twitter</FooterTitle>
              <p>A showcase of traditionally hard CSS problems, easily solved using flexbox.<br /> <a href="http://t.co/40aOhYRtjC" target="_blank" rel="noopener noreferrer">http://t.co/40aOhYRtjC</a><br /> via @philwalton<br /> <span><small>2 months ago</small></span></p>
            </FooterColumn> */}
            <FooterColumn>
              <FooterTitle>{frontmatter.contact_title}</FooterTitle>
              <p>
                <strong><FormattedMessage id="FOOTER_CONTACT_ADDRESS" />:</strong> {frontmatter.contact_address}<br />
                <strong><FormattedMessage id="FOOTER_CONTACT_PHONE" />:</strong> <span id="footer-phoneNumber">{frontmatter.contact_phone}</span><br />
                <strong><FormattedMessage id="FOOTER_CONTACT_EMAIL" />:</strong> <a id="footer-email" href={`mailto:${frontmatter.contact_email}`}>{frontmatter.contact_email}</a>
              </p>
              <FooterSocialIconTwitter href="/" target="_blank" />
              <FooterSocialIconFacebook href="/" target="_blank" />
              <FooterSocialIconPinterest href="/" target="_blank" />
            </FooterColumn>
            {/* <FooterColumn md={3}>
              <FooterTitle>Newsletter</FooterTitle>
              <p>Subscribe to our newsletter! Get tips, product updates and special offers.</p>
              <FooterForm inline>
                <FooterFormGroup>
                  <FooterFormInput type="text" name="newsletter" placeholder="mail@example.com" />
                  <FooterFormButtonSubmit>Join</FooterFormButtonSubmit>
                </FooterFormGroup>
              </FooterForm>
            </FooterColumn> */}
          </Row>
        </Container>
      )
    }}
  />
</FooterSection>

export default withTheme(Footer)
