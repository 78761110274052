const conjuctions = [
    '[a-z]',
    'aby',
    'ale',
    'ani',
    'aż',
    'bo',
    'by',
    'bym',
    'byś',
    'co',
    'czy',
    'dla',
    'do',
    'gdy',
    'gdyż',
    'iż',
    'jak',
    'lub',
    'na',
    'nad',
    'niż',
    'od',
    'oraz',
    'po',
    'pod',
    'te',
    'tej',
    'to',
    'we',
    'za',
    'zaś',
    'ze',
    'że',
  ];
  
const replacements = [' $1&nbsp;-&nbsp;', ' $1&nbsp;', ' $1,&nbsp;'];


const fixHangingConjuctions = (text) => {
  let pattern = [],
    patterns = [];
  for (let i = 0; i < replacements.length; i++) {
    for (let j = 0; j < conjuctions.length; j++) {
      patterns = [
        new RegExp(`\\s(${conjuctions[j]})(\\s)-\\s`, 'gi'),
        new RegExp(`\\s(${conjuctions[j]})(\\s)`, 'gi'),
        new RegExp(`\\s(${conjuctions[j]})(\\s)`, 'gi'),
      ]
      if (typeof pattern[i] === 'undefined') pattern[i] = [];
      pattern[i].push(patterns[i]);

      text = text.replace(pattern[i][j], replacements[i]);
    }
  }
  return text;
}


// const fixOrphans = (text) => {
//   return text.replace(/(\w+)\s(.*)/g, '$1&nbsp;$2');
// }


// const fixWidows = (text) => {
//   return text.replace(/\s(?=[^\s]*$)/g, '&nbsp;');
// }


const orphanRemove = (text) => {
  if (typeof text === "undefined") {
    return
  }
  
  text = fixHangingConjuctions(text);
  // text = fixWidows(text);
  // text = fixOrphans(text);
  return text;
}

export default orphanRemove